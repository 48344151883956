import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/userImage.png"
import APIS from "../../../apis"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
  const [etat, setEtat] = useState(1)

  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
  var etudiant_id = userAuthEtudiant.user.id_etudiant
  var nameEtud = userAuthEtudiant.user.name

  const logout = () => {
    localStorage.clear()
    localStorage.setItem("I18N_LANGUAGE", "fr")
    props.history.push("/login")
  }

  useEffect(async () => {
    console.log('etudiant_id', etudiant_id)
    const res = await APIS.post("check_profile_image", {
      type: "etudiants",
      id: etudiant_id,
    }).then(res => {
      setEtat(res.data.error)
    })
  }, [])


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {etat != 0 ? (
            <img
              className="rounded-circle header-profile-user"
              src={
                "https://scolarite.backcresus-institut.ovh/api/assetsmanager/etudiants/" +
                etudiant_id
              }
            />
          ) : (
            <img className="rounded-circle header-profile-user" src={user1} />
          )}
          <span
            style={{ color: "white" }}
            className="d-none d-xl-inline-block ms-2 me-1"
          >
            {nameEtud}
          </span>
          <i
            style={{ color: "white" }}
            className="mdi mdi-chevron-down d-none d-xl-inline-block"
          />
        </DropdownToggle>
        <DropdownMenu
          className={lng == "ar" ? "dropdown-menu-end-ar" : "dropdown-menu-end"}
        >
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          {/* <div className="dropdown-divider" /> */}
          <Link to="/logout" className="dropdown-item" onClick={logout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Déconnexion")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
ProfileMenu.propTypes = {
  history: PropTypes.object,
}
