import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Container, Card, CardBody } from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import AttestationPresence from "./AttestationPresence"

const AddDemande = props => {
  const [section, setSection] = useState(1)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setSection(id)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Demande")} | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Card>
              <CardBody>
                {section == 1 ? <AttestationPresence /> : null}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AddDemande))
AddDemande.propTypes = {
  t: PropTypes.any,
}
