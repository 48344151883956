import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
const DataTableDemande = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
  var etudiant_id = userAuthEtudiant.user.id_etudiant
  //
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  // Data Table
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  useEffect(async () => {
    if (localStorage.getItem("userAuthEtudiant")) {
      var userAuthEtudiant = JSON.parse(
        localStorage.getItem("userAuthEtudiant")
      )
      var etudiant_id = userAuthEtudiant.user.id_etudiant
    }
    const res = await APIS.post("etudiant/demande/list", {
      id: etudiant_id,
    }).then(res => {
      setOrders(res.data.Demandes)
    })
    setLoading(true)
  }, [])

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>{props.t("Pas de Resultat")}</h6>
  )
  
  const Columns = () => [
    {
      dataField: "name",
      text: props.t("Libélé"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "state",
      text: props.t("Status"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <i
            style={
              row.state == 0
                ? { color: "cornflowerblue", display: "flex" }
                : row.state == 2
                ? { color: "red", display: "flex" }
                : { color: "green", display: "flex" }
            }
            className={
              row.state == 0
                ? "fas fa-hourglass-half mt-3"
                : row.state == 2
                ? "fas fa-times mt-3"
                : "fas fa-check mt-3"
            }
          >
            <p style={{ paddingLeft: "5px", textAlign: "center" }}>
              {row.state == 0
                ? props.t("En Cours")
                : row.state == 2
                ? props.t("Refusé")
                : props.t("Validé")}
            </p>
          </i>
        </div>
      ),
    },
    {
      dataField: "updated",
      text: props.t("Crée"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Suppression",
      isDummyField: true,
      text: props.t("Suppression"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.state == 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ED6464", cursor: "pointer" }}
              onClick={() => toggle(row.id)}
              className="fas fa-trash-alt"
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "##A7A3A3", cursor: "pointer" }}
              className="fas fa-trash-alt"
              title="Demende déja traité"
            ></i>
          </div>
        ),
    },
  ]

  const { SearchBar } = Search

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }

  const toggleDelete = async () => {
    const res = await APIS.post("etudiant/demande/delete", {
      id: id,
    }).then(res => {
      const resD = APIS.post("etudiant/demande/list", {
        id: etudiant_id,
        lang: lng,
      }).then(resD => {
        setOrders(resD.data.Demandes)
      })
      setLoading(true)
    })
  }

  useEffect(() => {
    setModal(false)
  }, [orders])

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            direction: lng == "ar" ? "rtl" : "initial",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          toggle={toggle}
        >
          {props.t("Suppression d' une demande")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>{props.t("Voulez-vous supprimer cette Demande ?")}</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDelete}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableDemande))
DataTableDemande.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
