import React, { Component } from "react"
import { Card, Col, Row } from "reactstrap"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import API from "../../apis"
import pdf from "../../assets/images/pdfDocument.png"
import FileDownload from "js-file-download"

class DemandsGrids extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cin: "",
      nom: "",
      prenom: "",
    }
  }


  componentDidMount() {
    if (localStorage.getItem("userAuthEtudiant")) {
      var userAuthEtudiant = JSON.parse(
          localStorage.getItem("userAuthEtudiant")
      )
      var etudiant_id = userAuthEtudiant.user.id_etudiant

    }

    const res = API.post("etudiant/get_by_id", {
      id: etudiant_id,
    }).then(res => {
      this.setState({
        cin: res.data.etudiant.code,
        nom: res.data.etudiant.firstName,
        prenom: res.data.etudiant.lastName,
      })
    })
  }

  download = async () => {
    if (localStorage.getItem("userAuthEtudiant")) {
      var userAuthEtudiant = JSON.parse(
          localStorage.getItem("userAuthEtudiant")
      )
      var annee = userAuthEtudiant.user.annee
      var cin =userAuthEtudiant.user.cin
      var name =userAuthEtudiant.user.name
    }
    const res = await API.post(
        "resultat/download",
        {
          cin:cin,
          annee_id:annee,
          semestre_id:"Semestre 1"
        },
        { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, `${name + ".pdf"}`)
    })
  }
  download2 = async () => {
    if (localStorage.getItem("userAuthEtudiant")) {
      var userAuthEtudiant = JSON.parse(
          localStorage.getItem("userAuthEtudiant")
      )
      var annee = userAuthEtudiant.user.annee
      var cin =userAuthEtudiant.user.cin
      var name =userAuthEtudiant.user.name
    }
    const res = await API.post(
        "resultat/download",
        {
          cin: cin,
          annee_id:annee,
          semestre_id:"Semestre 2"
        },
        { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, `${name + ".pdf"}`)
    })
  }


  render() {
    return (
        <React.Fragment>
          <Row>
            <Row className="justify-content-center">
              <Col xl={11}>
                <div>
                  {/* <Row className="align-items-center">
                  <div className="mt-4">
                    <div>
                      <h5 style={{ color: "#556ee6" }} className="mb-0">
                        {this.props.t("Résultat")}
                      </h5>
                    </div>
                  </div>
                </Row> */}
                  <Row>
                    <Col sm={2}>
                      <Card className=" border shadow-none">
                        <a
                            onClick={() => this.download()}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          <div>
                            <img src={pdf} alt="" className="img-thumbnail" />
                          </div>
                          <div>
                            <div
                                className="justify-content-center pt-2"
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                            >
                              <p style={{ fontWeight: "bold" }}>Résultat Semestre 1</p>
                            </div>
                          </div>
                        </a>
                      </Card>
                    </Col>
                    <Col sm={2}>
                      <Card className=" border shadow-none">
                        <a
                            onClick={() => this.download2()}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          <div>
                            <img src={pdf} alt="" className="img-thumbnail" />
                          </div>
                          <div>
                            <div
                                className="justify-content-center pt-2"
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                            >
                              <p style={{ fontWeight: "bold" }}>Résultat Semestre 2</p>
                            </div>
                          </div>
                        </a>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Row>
        </React.Fragment>
    )
  }
}
DemandsGrids.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
export default withRouter(withTranslation()(DemandsGrids))
