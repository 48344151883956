import React, { Component } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import DemandsGrid from "./DemandsGrids"
import DataTableDemande from "./DataTableDemande"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"

class Demands extends Component {
  constructor(props) {
    super(props)
    this.state = { section: 1 }
  }

  back = () => {
    this.setState({ section: 1 })
  }
  renderBlock = section => {
    console.log(section)
    if (section === 1) {
      return <DataTableDemande />
    } else if (section === 2) {
      return <DemandsGrid setSection={this.state.section} back={this.back} />
    }
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <MetaTags>
            <title>{this.props.t("Demande")} | Cresus </title>
          </MetaTags>
          <Container fluid>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: this.state.section === 1,
                    })}
                    onClick={() => {
                      this.setState({ section: 1 })
                    }}
                  >
                    {this.props.t("Demande")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: this.state.section === 2,
                    })}
                    onClick={() => {
                      this.setState({ section: 2 })
                    }}
                  >
                    {this.props.t("Ajouter")} {this.props.t("Demande")}
                  </NavLink>
                </NavItem>
              </Nav>
              <Card>
                <CardBody>{this.renderBlock(this.state.section)}</CardBody>
              </Card>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Demands.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
export default withRouter(withTranslation()(Demands))
