import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Demands from "./Demands"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import PropTypes from "prop-types"
class index extends Component {
  componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Demandes")} | Cresus</title>
          </MetaTags>
          <Container fluid>
            <Demands />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
index.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(index))
