import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import APIS from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap"
import {withRouter} from "react-router"
import {Link} from "react-router-dom"
import {withTranslation} from "react-i18next"
import API from "../../apis";
import FileDownload from "js-file-download";

const DataTableMastere = props => {
    const lng = localStorage.getItem("I18N_LANGUAGE")
    var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
    var etudiant_id = userAuthEtudiant.user.id_etudiant
    //
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    // Data Table
    const [orders, setOrders] = useState([])

    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: props.t("Suivant"),
        prePageText: props.t("Précedent"),
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
    var annee = anneeScolaire.Annee.annee
    var name =userAuthEtudiant.user.name
    useEffect(async () => {

        const res = await APIS.post("etudiant/mastere/get_file_by_etudiant_id", {
            id: etudiant_id, annee
        }).then(res => {
            setOrders(res.data.EtudiantMastere)
        })
        setLoading(true)
    }, [])

    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>{props.t("Pas de Resultat")}</h6>
    )
    const download = (row, param1, annee) => {
        API.post(
            "etudiant/mastere/download",
            {
                annee: annee,
                niveaux: 1,
                id: etudiant_id
            },
            { responseType: "blob" }
        ).then(res => {
            const contentDisposition = res.headers["content-disposition"];
            const match = /filename="(.+)"/.exec(contentDisposition);
            const fileName = match ? match[1] : `${name}.${getFileExtension(res.data.type)}`;
            const blob = new Blob([res.data], { type: res.data.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    }


    const download1 = (row, param1, annee) => {
        API.post(
            "etudiant/mastere/download",
            {
                annee:annee,
                niveaux:2,
                id:etudiant_id
            },
            { responseType: "blob" }
        ).then(res => {
            const contentDisposition = res.headers["content-disposition"];
            const match = /filename="(.+)"/.exec(contentDisposition);
            const fileName = match ? match[1] : `${name}.${getFileExtension(res.data.type)}`;
            const blob = new Blob([res.data], { type: res.data.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
    }
    const download2 = (row, param1, annee) => {
        API.post(
            "etudiant/mastere/download",
            {
                annee:annee,
                niveaux:3,
                id:etudiant_id
            },
            { responseType: "blob" }
        ).then(res => {
            const contentDisposition = res.headers["content-disposition"];
            const match = /filename="(.+)"/.exec(contentDisposition);
            // console.log(match)
            const fileName = match ? match[1] : `${name}.${getFileExtension(res.data.type)}`;
            const blob = new Blob([res.data], { type: res.data.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
    }
    const download3 = (row, param1, annee) => {
        API.post(
            "etudiant/mastere/download",
            {
                annee:annee,
                niveaux:4,
                id:etudiant_id
            },
            { responseType: "blob" }
        ).then(res => {
            const contentDisposition = res.headers["content-disposition"];
            const match = /filename="(.+)"/.exec(contentDisposition);
            // console.log(match)
            const fileName = match ? match[1] : `${name}.${getFileExtension(res.data.type)}`;
            const blob = new Blob([res.data], { type: res.data.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
    }
    const download4 = (row, param1, annee) => {
        API.post(
            "etudiant/mastere/download",
            {
                annee:annee,
                niveaux:5,
                id:etudiant_id
            },
            { responseType: "blob" }
        ).then(res => {
            const contentDisposition = res.headers["content-disposition"];
            const match = /filename="(.+)"/.exec(contentDisposition);
            // console.log(match)
            const fileName = match ? match[1] : `${name}.${getFileExtension(res.data.type)}`;
            const blob = new Blob([res.data], { type: res.data.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
    }
    const download5 = (row, param1, annee) => {
        API.post(
            "etudiant/mastere/download",
            {
                annee:annee,
                niveaux:6,
                id:etudiant_id
            },
            { responseType: "blob" }
        ).then(res => {
            const contentDisposition = res.headers["content-disposition"];
            const match = /filename="(.+)"/.exec(contentDisposition);
            // console.log(match)
            const fileName = match ? match[1] : `${name}.${getFileExtension(res.data.type)}`;
            const blob = new Blob([res.data], { type: res.data.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
    }
    function getFileExtension(contentType) {
        switch (contentType) {
            case "application/pdf":
                return "pdf";
            case "image/jpeg":
                return "jpeg";
            // Vous pouvez ajouter d'autres types de fichiers si nécessaire
            default:
                return "unknown";
        }
    }

    const Columns = () => [
        {
            dataField: "file_1",
            text: props.t("Relevé de note premiére année"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <i style={{color: "#955cd5", cursor: "pointer"}}
                   className="fas fa-file-download"
                   onClick={() => download(row.id,1,annee)}
                ></i>
            ),
        },
        {
            dataField: "file_2",
            text: props.t("Relevé de note deuxième année"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <i style={{color: "#955cd5", cursor: "pointer"}}
                   className="fas fa-file-download"
                   onClick={() => download1(row.id,2,annee)}
                ></i>
            ),
        },
        {
            dataField: "file_3",
            text: props.t("Relevé de note troisième année"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <i style={{color: "#955cd5", cursor: "pointer"}}
                   className="fas fa-file-download"
                   onClick={() => download2(row.id,3,annee)}
                ></i>
            ),
        },
        {
            dataField: "file_4",
            text: props.t("Fiche de renseignement"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <i style={{color: "#955cd5", cursor: "pointer"}}
                   className="fas fa-file-download"
                   onClick={() => download3(row.id,4,annee)}
                ></i>
            ),
        },
        {
            dataField: "file_5",
            text: props.t("Relevé de note d'année redoublement (1)"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                row.file_5 != "" ? (
                <i style={{color: "#955cd5", cursor: "pointer"}}
                   className="fas fa-file-download"
                   onClick={() => download4(row.id,3,annee)}
                ></i>
                ):(
                    <i style={{color: "#a7a3a3", cursor: "pointer"}}
                       className="fas fa-file-download"

                    ></i>
                )
            ),
        },
        {
            dataField: "file_6",
            text: props.t("Relevé de note d'année redoublement (2)"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                row.file_6 != "" ? (
                <i style={{color: "#955cd5", cursor: "pointer"}}
                   className="fas fa-file-download"
                   onClick={() => download5(row.id,3,annee)}
                ></i>
                ):(
                    <i style={{color: "#a7a3a3", cursor: "pointer"}}
                       className="fas fa-file-download"

                    ></i>
                )
            ),
        },

        {
            dataField: "Suppression",
            isDummyField: true,
            text: props.t("Suppression"),
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) =>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <i
                        style={{color: "#ED6464", cursor: "pointer"}}
                        onClick={() => toggle(row.id)}
                        className="fas fa-trash-alt"
                    ></i>
                </div>
        },
    ]

    const {SearchBar} = Search

    const toggle = id => {
        setModal(!modal)
        setId(id)
    }

    const toggleDelete = async () => {
        const res = await APIS.post("etudiant/mastere/delete_files", {
            id: etudiant_id, annee
        }).then(res => {
            const resD = APIS.post("etudiant/mastere/get_file_by_etudiant_id", {
                id: etudiant_id, annee
            }).then(resD => {
                setOrders(resD.data.EtudiantMastere)
            })
            setLoading(true)
        })
    }

    useEffect(() => {
        setModal(false)
    }, [orders])

    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            {props.t("Chargement en cours...")}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        direction: lng == "ar" ? "rtl" : "initial",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "17px",
                        fontWeight: "bold",
                    }}
                    toggle={toggle}
                >
                    {props.t("Suppression d' une demande")}
                </div>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>{props.t("Voulez-vous supprimer cette Demande ?")}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    {props.t("Oui")}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    {props.t("Non")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(DataTableMastere))
DataTableMastere.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.any,
}
