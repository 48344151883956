import PropTypes from "prop-types"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
// Import all middleware
import Authmiddleware from "./routes/route"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
// Import scss
import "./assets/scss/theme.scss"
import fakeBackend from "./helpers/AuthType/fakeBackend"
import React, {useEffect, useState} from "react"
// import { useIdleTimer } from "react-idle-timer"
// Activating fake backend
fakeBackend()

const App = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  // const handleOnIdle = event => {
  //   if (event === undefined) {
  //     localStorage.clear()
  //   }
  // }
  // const handleOnActive = event => {}
  // const handleOnAction = event => {}
  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 100 * 30 * 10,
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // })
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    window.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    });
    document.addEventListener('contextmenu', handleContextMenu);
    const handleKeyDown = (event) => {
      if (event.key === 'F12') {
        event.preventDefault();
        // alert('L\'inspection du navigateur n\'est pas autorisée.');
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <div>
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {lng == "ar"
              ? authProtectedRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ))
              : authProtectedRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ))}
          </Switch>
        </Router>
      </React.Fragment>
    </div>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
