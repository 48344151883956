import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Spinner } from "reactstrap"
import API from "../../apis"
import ISGGB from "../../assets/images/ISGB.jpg"
import tunisie_rep from "../../assets/images/tn_rep.png"

const ResultPrincipaleSemestreDeux = props => {
  if (localStorage.getItem("userAuthEtudiant")) {
    var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
    var etudiant_id = userAuthEtudiant.user.id_etudiant
    var nameEtud = userAuthEtudiant.user.name
  }
  const [loading, setLoading] = useState(false)
  const [notes, setNotes] = useState([])
  const [specialite, setSpecialite] = useState("")

  useEffect(async () => {
    const res = API.post("etudiant/get_notes", {
      etudiant_id: etudiant_id,
      semestre_id: 2,
      session_id: 1,
    }).then(res => {
      setNotes(res.data.Etudiant.note)
      setSpecialite(res.data.Etudiant.specialite)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <div className="container">
            <div className="row" style={{ paddingTop: "30px" }}>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                République Tunisienne
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Ministère de l'enseignement supérieur,"}
                </div>
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  {" "}
                  de la Recherche Scientifique
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  Université de Gabès
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"L'INSTITUT SUPERIEUR DE GESTION GABES"}
                </div>
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "70%" }} src={ISGGB} />
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "28%" }} src={tunisie_rep} />
              </div>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                الجمهورية التونسية
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  وزارة التعليم العالي والبحث العلمي
                </div>
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  جامعة قابس
                </div>
                <div
                  className="col"
                  style={{
                    textAlign: "center",
                    marginRight: 0,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  المعهد العالي للتصرف بقابس{" "}
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                marginTop: "0.1%",
                color: "black",
              }}
            />
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div
                  style={{
                    display: "flex",
                    fontSize: "15px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ paddingRight: "4px", fontWeight: "bold" }}>
                    Résultat
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "15px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Session :</span>
                  <span style={{ fontWeight: "bold" }}>Principale</span>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "13px",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ paddingRight: "4px" }}>
                      Année Universitaire:{" "}
                    </span>
                    <span style={{ fontWeight: "bold" }}>2023/2024</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Semestre:</span>
                  <span style={{ fontWeight: "bold" }}>1</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "end",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Spécialité :</span>
                  <span style={{ fontWeight: "bold" }}>{specialite}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "end",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Etudiant :</span>
                  <span style={{ fontWeight: "bold" }}>{nameEtud}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-bordered text-center">
                <thead className="table table-light">
                  <tr>
                    <th className="div-center">Matière</th>
                    <th className="div-center">Note présence</th>
                    <th className="div-center">DS1</th>
                    <th className="div-center">DS2</th>
                    <th className="div-center">Test</th>
                    <th className="div-center">TP</th>
                    <th className="div-center">Examen Final</th>
                  </tr>
                </thead>
                {notes.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td className="div-center">{el.matiere}</td>
                      <td className="div-center">{el.note_presence}</td>
                      <td className="div-center">{el.note_ds1}</td>
                      <td className="div-center">{el.note_ds2}</td>
                      <td className="div-center">{el.note_test}</td>
                      <td className="div-center">{el.note_tp}</td>
                      <td className="div-center">{el.note_examen_finale}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default ResultPrincipaleSemestreDeux
ResultPrincipaleSemestreDeux.propTypes = {
  history: PropTypes.object,
}
