import React, { Component } from "react"
import { Card, Col, Row } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
// image
import AttestationPresence from "../../assets/images/AttestationPresence.jpg"
//
class DemandsGrids extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nouveautes: [{ id: 1, image: `${AttestationPresence}` }],
    }
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Row className="justify-content-center">
            <Col xl={11}>
              <div>
                <Row className="align-items-center">
                  <div className="mt-4">
                    <div>
                      <h5 style={{ color: "#556ee6" }} className="mb-0">
                        {this.props.t("Les Demandes")}
                      </h5>
                    </div>
                  </div>
                </Row>
                <hr className="mb-4" />
                <Row>
                  {this.state.nouveautes.map(element => (
                    <Col key={element.id} sm={2}>
                      <Card className=" border shadow-none">
                        <div>
                          <img
                            src={element.image}
                            alt=""
                            className="img-thumbnail"
                          />
                        </div>
                        <div>
                          <div
                            className="justify-content-center pt-2"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() =>
                              this.props.history.push(
                                "/AddDemande?id=" + element.id
                              )
                            }
                          >
                            <p style={{ fontWeight: "bold" }}>
                              {this.props.t("Ajouter")}
                              <i
                                style={{ paddingLeft: "5px" }}
                                className="far fa-plus-square"
                              ></i>
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Row>
      </React.Fragment>
    )
  }
}
DemandsGrids.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
export default withRouter(withTranslation()(DemandsGrids))
