import React, { useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../apis"
import { Col, Input, Row, Label, Form, Button, CardTitle } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"

const AttestationPresence = props => {
  if (localStorage.getItem("userAuthEtudiant")) {
    var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
    var etudiant_id = userAuthEtudiant.user.id_etudiant
  }
  const [raison, setRaison] = useState("")
  const [langue, setLangue] = useState([
    { value: 1, label: "Français" },
    { value: 2, label: "Arabe" },
  ])
  const [selectLangue, setSelectLangue] = useState([])

  const save = async () => {
    if (raison == "") {
      toast.error("⛔ Raison obligatoire !", {
        containerId: "A",
      })
    } else if (selectLangue != "") {
      const res = await APIS.post("etudiant/demande/add", {
        id: etudiant_id,
        attestation_id: 1,
        raison: raison,
        langue: selectLangue.label,
      }).then(res => {
        props.history.push("/Demands")
      })
    } else {
      toast.error("⛔ Langue obligatoire !", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <CardTitle className="h4">
          {props.t("Attestation De Présence")}
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Raison")}
                </Label>
                <Input
                  type="textarea"
                  className="form-control"
                  placeholder={props.t("Raison")}
                  onChange={e => setRaison(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Langue")}
                </Label>
                <Select
                  options={langue}
                  type="text"
                  onChange={e => setSelectLangue(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={() => props.history.push("/Demands")}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AttestationPresence))
AttestationPresence.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
